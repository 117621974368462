import { PatronLoanHistoryResponse } from "../client/interfaces";
import { mockISOdateString } from "./ISOdateString";

export const mockLoanHistory: PatronLoanHistoryResponse = {
  isEndOfResults: true,
  loanHistory: [
    {
      addedToHistoryTime: mockISOdateString("datetime", -30),
      checkinTime: mockISOdateString("datetime", -1),
      checkoutTime: mockISOdateString("datetime", -30),
      dueDate: mockISOdateString("date", -1),
      id: "ix:219265,1",
      itemId: "219265",
      loanId: "219265",
      loanType: "redia.loan",
      publicationId: "219265",
      wasManuallyAdded: false,
    },
    {
      addedToHistoryTime: mockISOdateString("datetime", -28),
      checkinTime: mockISOdateString("datetime", 1),
      checkoutTime: mockISOdateString("datetime", -28),
      dueDate: mockISOdateString("date", 1),
      id: "ix:341561,1",
      itemId: "341561",
      loanId: "341561",
      loanType: "redia.loan",
      publicationId: "341561",
      wasManuallyAdded: true,
    },
    {
      addedToHistoryTime: mockISOdateString("datetime", -10),
      checkinTime: mockISOdateString("datetime", 15),
      checkoutTime: mockISOdateString("datetime", -10),
      dueDate: mockISOdateString("date", 15),
      id: "ix:544751,3",
      itemId: "544751",
      loanId: "544751",
      loanType: "redia.loan",
      publicationId: "544751",
      wasManuallyAdded: false,
    },
  ],
  total: 3,
};
