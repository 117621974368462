import { isProduction } from "@libry-content/common";
import { addBreadcrumb } from "@sentry/core";
import type { Breadcrumb } from "@sentry/types";

export const createSentryBreadcrumbFactory =
  (category: string) =>
  (message: string, data: Breadcrumb["data"] = undefined, level: Breadcrumb["level"] = "info") => {
    const breadcrumb: Breadcrumb = {
      type: "debug",
      category,
      level,
      message,
      data,
    };
    if (!isProduction) {
      console.debug(breadcrumb);
    }
    addBreadcrumb(breadcrumb);
  };
