import addDays from "date-fns/addDays";
import dateFnsFormat from "date-fns/format";

export const mockISOdateString = (format: "date" | "datetime", daysFromNow: number) => {
  const newDate = addDays(new Date(), daysFromNow);

  switch (format) {
    case "datetime":
      return newDate.toISOString();
    case "date":
    default:
      return dateFnsFormat(newDate, "yyyy-MM-dd");
  }
};
