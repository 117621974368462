import { RediaPlatformSession } from "./interfaces";

export interface SessionStore {
  get: () => RediaPlatformSession | null;
  set: (data: RediaPlatformSession) => void;
  patch: (data: Partial<RediaPlatformSession>) => void;
  clear: () => void;
  onStorageEvent: (event: StorageEvent) => void;
}

export interface SessionChangeEvent {
  oldValue: RediaPlatformSession | null;
  newValue: RediaPlatformSession | null;
}

/**
 * Standard session store that can be used with SessionStorage or LocalStorage.
 */
export const createSessionStore = ({
  storage,
  key,
  onChange,
}: {
  storage: Storage;
  key: string;
  onChange?: (props: SessionChangeEvent) => void | Promise<void>;
}): SessionStore => {
  const get = () => {
    const data = storage.getItem(key);
    return data ? (JSON.parse(data) as RediaPlatformSession) : null;
  };
  const set = (data: RediaPlatformSession) => {
    const oldValue = get();
    storage.setItem(key, JSON.stringify(data));
    if (onChange) void onChange({ oldValue, newValue: data });
  };
  const clear = () => {
    const oldValue = get();
    storage.removeItem(key);
    if (onChange) void onChange({ oldValue, newValue: null });
  };
  const patch = (data: Partial<RediaPlatformSession>) => {
    const oldValue = get();
    if (oldValue === null) {
      throw new Error("Cannot patch a non-existent session");
    }
    const newValue = {
      ...oldValue,
      ...data,
    };
    storage.setItem(key, JSON.stringify(newValue));
    if (onChange) void onChange({ oldValue, newValue });
  };
  // Set initial state upon loading
  if (onChange) void onChange({ oldValue: null, newValue: get() });
  return {
    get,
    set,
    clear,
    patch,
    onStorageEvent: (event: StorageEvent) => {
      if (event.key === key) {
        // Oppdaterer React state når bruker har logget inn/ut i annet vindu/annen fane.
        console.debug("User state changed in other tab/window");
        const oldValue = event.oldValue ? (JSON.parse(event.oldValue) as RediaPlatformSession) : null;
        const newValue = event.newValue ? (JSON.parse(event.newValue) as RediaPlatformSession) : null;
        if (onChange) void onChange({ oldValue, newValue });
      }
    },
  };
};

/**
 * Simple in-memory store that can be used in API routes (backend) and tests.
 */
export const createInMemorySessionStore = (): SessionStore => {
  let session: RediaPlatformSession | null;
  return {
    set: (newSession: RediaPlatformSession) => {
      session = newSession;
    },
    patch: (data: Partial<RediaPlatformSession>) => {
      if (session == null) throw new Error("Cannot patch a non-existent session");
      session = { ...session, ...data };
    },
    get: () => session,
    clear: () => {
      session = null;
    },
    onStorageEvent: () => {},
  };
};
