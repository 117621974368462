import {
  createRediaPlatformClient,
  createRediaPlatformMockClient,
  createSessionStore,
  Patron,
  RediaPlatform,
  SessionExpired,
} from "@libry-content/redia-platform";
import { SessionChangeEvent } from "@libry-content/redia-platform/src/client/sessionStore";
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useToggle } from "react-use";
import { useSWRConfig } from "swr";
import { useCommonData } from "../components/layout/CommonDataProvider";
import { useClientSideReady } from "../utils/hooks/useClientSideReady";

export interface RediaPlatformContextType {
  isRediaPlatformConfigured: boolean;
  isSessionReady: boolean;
  lastSessionExpired: SessionExpired | undefined;
  rediaPlatform: RediaPlatform | undefined;
  user: Patron | undefined;
  useMockClient: boolean;
  toggleUseMockClient: () => void;
}

const initialState: RediaPlatformContextType = {
  rediaPlatform: undefined,
  user: undefined,
  isSessionReady: false,
  lastSessionExpired: undefined,
  isRediaPlatformConfigured: false,
  useMockClient: false,
  toggleUseMockClient: () => null,
};

export const RediaPlatformContext = createContext(initialState);

export const useRediaPlatformContext = () => useContext(RediaPlatformContext);

const sessionStorageKey = "redia-platform-session";

export const logoutConfirmationParameter = "logoutConfirmation" as const;

/**
 * When using SWR to cache user data, always use this constant as the first element in an array cache key.
 * This ensures that cached user data is cleared by the `clearSwrUserCache()` function upon logout.
 */
export const swrUserNamespace = "user";

function RediaPlatformProvider({ children }: { children: ReactNode }) {
  const [useMockClient, toggleUseMockClient] = useToggle(false);
  const { customerId, environment } = useCommonData()?.site?.librarySystem?.rediaPlatformConfig ?? {};
  const clientSideReady = useClientSideReady();
  const [user, setUser] = useState<Patron | undefined>(undefined);
  const [isSessionReady, setIsSessionReady] = useState<boolean>(false);
  const [lastSessionExpired, setLastSessionExpired] = useState<SessionExpired | undefined>(undefined);
  const isRediaPlatformConfigured = typeof customerId !== "undefined";
  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (isSessionReady && !user) {
      // Clear swr user cache if no user logged in
      mutate((key: unknown) => Array.isArray(key) && key[0] === swrUserNamespace, undefined, { revalidate: false });
    }
  }, [isSessionReady, mutate, user]);

  const handleSessionChange = useCallback(({ oldValue, newValue }: SessionChangeEvent) => {
    const oldPatronId = oldValue?.user?.identifiers?.[0]?.value;
    const newPatronId = newValue?.user?.identifiers?.[0]?.value;

    if (newPatronId !== oldPatronId) {
      // Oppdaterer React state når bruker har logget inn/ut
      if (newPatronId) {
        console.debug("User is logged in", newPatronId);
      } else {
        console.debug("User is logged out", oldPatronId);
        const url = new URL(location.toString());
        url.searchParams.delete(logoutConfirmationParameter);
        url.searchParams.append(logoutConfirmationParameter, "true");
        location.assign(url.href);
      }
      setUser(newValue?.user ?? undefined);
    }
  }, []);

  const sessionStore = useMemo(
    () =>
      !clientSideReady
        ? undefined
        : createSessionStore({
            storage: localStorage,
            key: sessionStorageKey,
            onChange: (event) => {
              handleSessionChange(event);
              setIsSessionReady(true);
            },
          }),
    [clientSideReady, handleSessionChange]
  );

  useEffect(() => {
    if (sessionStore) {
      window.addEventListener("storage", sessionStore.onStorageEvent);
      return () => {
        window.removeEventListener("storage", sessionStore.onStorageEvent);
      };
    }
  }, [sessionStore]);

  const rediaClientFactory = useMockClient ? createRediaPlatformMockClient : createRediaPlatformClient;
  const rediaPlatform = useMemo(
    () =>
      !sessionStore || typeof customerId === "undefined" || !environment
        ? undefined
        : rediaClientFactory({
            customerId,
            environment,
            sessionStore,
            onSessionExpired(sessionExpired: SessionExpired) {
              const inactiveTimeStr = sessionExpired.inactiveTimeSeconds
                ? `, inactive for ${sessionExpired.inactiveTimeSeconds} seconds`
                : "";
              console.debug(`⏰ Redia Platform session expired: ${sessionExpired.reason}${inactiveTimeStr}`);
              setLastSessionExpired(sessionExpired);
            },
          }),
    [sessionStore, customerId, environment, rediaClientFactory]
  );

  return (
    <RediaPlatformContext.Provider
      value={{
        rediaPlatform,
        user,
        isSessionReady,
        lastSessionExpired,
        isRediaPlatformConfigured,
        useMockClient,
        toggleUseMockClient,
      }}
    >
      {children}
    </RediaPlatformContext.Provider>
  );
}

export default RediaPlatformProvider;
