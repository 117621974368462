import { PatronLoan } from "../client/interfaces";
import { mockISOdateString } from "./ISOdateString";

export const mockLoans: PatronLoan[] = [
  {
    createdTime: mockISOdateString("datetime", -30),
    dueDate: mockISOdateString("date", -1),
    id: "ix:219265,1",
    itemId: "219265",
    publicationId: "219265",
    renew: {
      state: "redia.renewable",
    },
    loanType: "redia.loan",
  },
  {
    createdTime: mockISOdateString("datetime", -28),
    dueDate: mockISOdateString("date", 1),
    id: "ix:341561,1",
    itemId: "341561",
    publicationId: "341561",
    renew: {
      state: "redia.renewable_later",
    },
    loanType: "redia.loan",
  },
  {
    createdTime: mockISOdateString("datetime", -10),
    dueDate: mockISOdateString("date", 15),
    id: "ix:544751,3",
    itemId: "544751",
    publicationId: "544751",
    renew: {
      state: "redia.not_renewable",
    },
    loanType: "redia.loan",
  },
];
