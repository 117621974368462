/**
 * Query serializer that serializes query strings according to the `style=form`
 * `explode=false` OpenAPI query parameter serialization style.
 *
 * With this style, a non-escaped comma (,) is used to separate array values,
 * while an escaped comma (%2C) is interpreted as being part of the array value.
 * We cannot use URLSearchParams, since it does not support this style of array
 * serialization.
 */
export function standardQuerySerializer<T = unknown>(params: T): string {
  return params && typeof params === "object"
    ? Object.entries(params)
        .map(([key, value]) => `${key}=${serializeValue(value)}`)
        .join("&")
    : "";
}

function serializeValue(value: unknown) {
  if (Array.isArray(value)) {
    return value.map((entry) => encodeURIComponent(entry)).join(",");
  }
  if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
    return encodeURIComponent(value);
  }
  throw new Error(`Cannot serialize query string value '${JSON.stringify(value)}'`);
}
