import { BranchInformation } from "../client/interfaces";

export const mockBranches: Record<string, BranchInformation | undefined> = {
  rbb: {
    code: "rbb",
    description:
      "Revetal bibliotek er lokalisert midt i Revetal sentrum. Her får du tilgang til  en variert biblioteksamling, kan benytte deg av PC og printer, låne grupperom og besøke Wilmas Kafé. Vi har også spennende arrangement for alle aldre gjennom hele året.\n\nDu kan nå oss på telefon i betjent åpningstid.",
    emailAddress: "revetal.biblioteket@tonsberg.kommune.no",
    externals: {
      identifiers: [
        {
          type: "isil",
          value: "NO-2071600",
        },
      ],
    },
    geoCoordinates: [],
    imageUrl: "https://cdn.sanity.io/images/v0tvkqmw/production/0df374bf691da1772e35767b6b01425a45a48a71-1240x1753.jpg",
    name: "Revetal bibliotek",
    phoneNumbers: [
      {
        number: "+47 33406415",
        type: "mobile",
      },
    ],
    postalAddress: {
      city: "Tønsberg",
      postalCode: "3103",
      streetAddress: "Postboks 2131",
    },
    socialMedia: {
      facebook: "https://www.facebook.com/revetalbibliotek",
      instagram: "https://www.instagram.com/revetal.bibliotek",
    },
    visitingAddress: {
      city: "Revetal",
      postalCode: "3174",
      streetAddress: "Regata 1A",
    },
  },
  tjøm: {
    code: "tjøm",
    description:
      "Tjøme bibliotek ligger i det tidligere kommunehuset i Tjøme sentrum. Her kan du låne bøker, filmer, lydbøker m.m. Vi har egen samling med lokalhistorisk litteratur. Vi har kopimaskin, trådløst nett og pcer med utskriftsmulighet.",
    emailAddress: "tjome.biblioteket@tonsberg.kommune.no",
    externals: {
      identifiers: [
        {
          type: "isil",
          value: "NO-2072300",
        },
      ],
    },
    geoCoordinates: [],
    imageUrl: "https://cdn.sanity.io/images/v0tvkqmw/production/18e62264b9da5bb1bdd92881e9328a28f05facfe-1024x517.jpg",
    name: "Tjøme bibliotek",
    phoneNumbers: [
      {
        number: "+47 33067833",
        type: "mobile",
      },
    ],
    postalAddress: {
      city: "TØNSBERG",
      postalCode: "3103",
      streetAddress: "Postboks 2131",
    },
    socialMedia: {
      facebook: "https://www.facebook.com/Tjomebibliotek",
      instagram: "https://www.instagram.com/tnbibliotek/",
    },
    visitingAddress: {
      city: "TJØME",
      postalCode: "3145",
      streetAddress: "Rødsgata 36",
    },
  },
  tøb: {
    code: "tøb",
    description:
      "På Hovedbiblioteket i Tønsberg får du tilgang til en variert biblioteksamling, kan benytte deg av PC og printer, låne grupperom og besøke With bok og kaffe. Vi har også spennende arrangement for alle aldre gjennom hele året. \n\nI ukedagene åpner vi dørene kl. 08.00 og har betjent fra kl. 10.00. \n\nVi har telefontid fra kl. 10.00 - 14.00",
    emailAddress: "biblioteket@tonsberg.kommune.no",
    externals: {
      identifiers: [
        {
          type: "isil",
          value: "NO-2070400",
        },
      ],
    },
    geoCoordinates: [],
    imageUrl: "https://cdn.sanity.io/images/v0tvkqmw/production/f1c5acc90c830f7eadaafaee943c22cb5f29c819-8256x5504.jpg",
    name: "Hovedbiblioteket",
    phoneNumbers: [
      {
        number: "+47 33 34 86 20",
        type: "mobile",
      },
    ],
    postalAddress: {
      city: "Tønsberg",
      postalCode: "3103",
      streetAddress: "Postboks 2131",
    },
    socialMedia: {
      facebook: "https://www.facebook.com/TonsbergogFaerderbibliotek",
      instagram: "https://www.instagram.com/tnbibliotek/",
      youtube: "https://www.youtube.com/user/TNbibliotek",
    },
    visitingAddress: {
      city: "Tønsberg",
      postalCode: "3126",
      streetAddress: "Storgaten 16",
    },
  },
};
