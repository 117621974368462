import { useState, useEffect } from "react";

export const useClientSideReady = () => {
  const [clientSideReady, setClientSideReady] = useState(false);

  // Show content after the first client render cycle
  useEffect(() => {
    setClientSideReady(true);
  }, []);

  return clientSideReady;
};
