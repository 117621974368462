import { Patron } from "../client/interfaces";

export const mockPatron: Patron = {
  address: {
    city: "Oslo",
    country: "Norge",
    postalCode: "3272",
    street: "Mockveien 5",
    type: "PatronV2",
  },
  emails: [
    {
      email: "test@biblioteksentralen.no",
      isDefault: true,
    },
  ],
  fullName: "Mocksnes, Testis",
  givenName: "Testis",
  identifiers: [
    {
      type: "local",
      value: "N12345678",
    },
    {
      type: "email",
      value: "test@bibsyst.no",
    },
    {
      type: "phone_number",
      value: "+479000000",
    },
  ],
  phoneNumbers: [
    {
      isDefault: true,
      number: "+479000000",
      type: "mobile",
    },
    {
      isDefault: true,
      number: "+4712345678",
      type: "landline",
    },
  ],
  pickupLocation: {
    branchCode: "mbh",
    branchName: "Presentasjon",
  },
  surname: "Mocksnes",
};
