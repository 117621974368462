import createClient from "openapi-fetch";
import { RediaPlatformEnvironment, RediaPlatformSession, SessionExpiredCallback, SessionToken } from "./interfaces";
import { SessionStore } from "./sessionStore";
import { configurationPaths } from "../types";
import { RediaPlatformConfigurationApiError } from "./errors";
import { getRediaPlatformBaseUrl } from "./getRediaPlatformBaseUrl";
import { createSentryBreadcrumbFactory } from "./sentry";

const maxInactiveTimeSeconds = 3600;

type SessionRequestResult = { session: RediaPlatformSession; error?: never } | { session?: never; error: Error };

// The three types below should really be available globally, but are not for some reason. Tsconfig issue?
type RequestInfo = Parameters<typeof fetch>[0];
type RequestInit = NonNullable<Parameters<typeof fetch>[1]>;
type FetchAPI = (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>;

interface RediaPlatformSessionMiddlewareProps {
  environment: RediaPlatformEnvironment;
  clientId: string;
  customerId: string;
  sessionStore: SessionStore;
  onSessionExpired?: SessionExpiredCallback;
}

// Log debug breadcrumbs to Sentry
const debug = createSentryBreadcrumbFactory("session");

const debugSession = (session: RediaPlatformSession | null) => ({
  patronId: session?.user?.identifiers?.[0]?.value ?? null,
  // For security reasons, we don't log the whole token, just a few bytes
  sessionId: session?.token ? session?.token.token.substring(session?.token.token.length - 4) : null,
  expires: session?.token.expiresTime ?? null,
  loginTime: session?.loginTime ?? null,
  lastActiveTime: session?.lastActiveTime ?? null,
});

/**
 * Fetch middleware that is responsible for managing the Redia Platform session.
 * It creates the session transparently when needed, and could be extended to
 * refresh the session automatically in the future.
 */
export class RediaPlatformSessionMiddleware {
  /**
   * Redia Platform client identifier.
   */
  private readonly clientId: string;

  /**
   * Redia Platform customer identifier.
   */
  private readonly customerId: string;

  /**
   * API client for the Redia Platform Configuration API, used to fetch session tokens.
   */
  private readonly configurationApi: ReturnType<typeof createClient<configurationPaths>>;

  /**
   * Key-value store where we store the current session. The implementation will
   * normally be based on LocalStorage or SessionStorage, but can also be an
   * in-memory store for testing.
   */
  private readonly sessionStore: SessionStore;

  /**
   * Pending session creation request.
   */
  private pendingSessionRequest: Promise<SessionRequestResult> | undefined;

  /**
   * Callback function to call when a session has expired.
   */
  onSessionExpired?: SessionExpiredCallback;

  constructor({
    environment,
    clientId,
    customerId,
    sessionStore,
    onSessionExpired,
  }: RediaPlatformSessionMiddlewareProps) {
    this.clientId = clientId;
    this.customerId = customerId;
    this.sessionStore = sessionStore;
    this.onSessionExpired = onSessionExpired;
    this.configurationApi = createClient<configurationPaths>({
      baseUrl: getRediaPlatformBaseUrl({ api: "configuration", environment }),
    });

    const session = this.sessionStore.get();
    if (session && session?.customerId !== customerId) {
      // Tøm sesjonen hvis `customerId` har blitt endret i Nettstedinnstillinger i Sanity.
      console.log("Clearing Redia Platform session because customerId has changed");
      this.sessionStore.clear();
    }
    debug("Initialized session middleware", debugSession(session));
  }

  public getSession() {
    return this.sessionStore.get();
  }

  /**
   * Clear the current session. Call this after logout.
   */
  public clearSession() {
    debug("Clearing session", debugSession(this.getSession()));
    return this.sessionStore.clear();
  }

  private async requestNewSession(): Promise<SessionRequestResult> {
    debug("Requesting new session");
    const response = await this.configurationApi.POST("/api/session/token", {
      body: {
        customerCode: this.customerId,
        secret: this.clientId, // "Ikke egentlig en secret" iflg. Redia. Vi kaller det clientId.
        productCode: "content",
        version: "v1.99.99",
        platform: "web",
      },
    });
    if (response.error) {
      debug("Failed to get new session", {
        error: response.error,
      });
      return { error: new RediaPlatformConfigurationApiError(response.error) };
    }
    const session: RediaPlatformSession = {
      token: response.data.token,
      refreshToken: response.data.refreshToken,
      customerId: this.customerId,
      user: null,
      lastActiveTime: new Date().toISOString(),
      loginTime: null,
    };
    debug("Got new session", debugSession(session));
    return { session };
  }

  private async requestSessionRefresh(session: RediaPlatformSession): Promise<SessionRequestResult> {
    if (!session.refreshToken) {
      debug("Cannot refresh session without refreshToken");
      return { error: new Error("Session cannot be refreshed") };
    }
    debug("Refreshing session");
    const response = await this.configurationApi.POST("/api/session/token/refresh", {
      body: {
        refreshToken: session.refreshToken.token,
      },
    });
    if (response.error) {
      debug("Failed to refresh session", {
        error: response.error,
      });
      return { error: new RediaPlatformConfigurationApiError(response.error) };
    }
    const refreshedSession: RediaPlatformSession = {
      ...session,
      token: response.data.token,
      lastActiveTime: new Date().toISOString(),
    };
    debug("Refreshed session", debugSession(refreshedSession));
    return { session: refreshedSession };
  }

  /**
   * Create a new session.
   *
   * This method can safely be called multiple times. If a session request is pending,
   * it will wait for the pending request instead of creating a new one.
   */
  private async newSession(): Promise<SessionRequestResult> {
    if (this.pendingSessionRequest) {
      return this.pendingSessionRequest;
    }
    this.pendingSessionRequest = this.requestNewSession();
    try {
      const { session, error } = await this.pendingSessionRequest;
      if (session) {
        this.sessionStore.set(session);
        return { session };
      }
      return { error };
    } finally {
      this.pendingSessionRequest = undefined;
    }
  }

  /**
   * Refresh an existing session.
   *
   * This method can safely be called multiple times. If a session request is pending,
   * it will wait for the pending request instead of creating a new one.
   */
  private async refreshSession(session: RediaPlatformSession): Promise<SessionRequestResult> {
    if (this.pendingSessionRequest) {
      return this.pendingSessionRequest;
    }
    this.pendingSessionRequest = this.requestSessionRefresh(session);
    try {
      const { session, error } = await this.pendingSessionRequest;
      if (session) {
        this.sessionStore.set(session);
        return { session };
      }
      return { error };
    } finally {
      this.pendingSessionRequest = undefined;
    }
  }

  public hasSessionToken() {
    return !!this.sessionStore.get();
  }

  private isSessionTokenExpired({ expiresTime }: SessionToken) {
    const expirationDate = expiresTime ? new Date(expiresTime) : undefined;
    // Consider the session expired 10 seconds (in ms) before it actually expires
    return expirationDate && expirationDate < new Date(Date.now() + 10000);
  }

  private expireInactiveSession() {
    const session = this.sessionStore.get();
    if (session?.lastActiveTime) {
      const inactiveTimeSeconds = (new Date().getTime() - new Date(session.lastActiveTime).getTime()) / 1000;
      if (inactiveTimeSeconds > maxInactiveTimeSeconds) {
        debug("Expiring session because of inactivity", debugSession(session));
        if (this.onSessionExpired) void this.onSessionExpired({ reason: "inactive_for_too_long", inactiveTimeSeconds });
        // TODO: Also request deauthenticate here?
        this.clearSession();
      }
    }
  }

  private async refreshSessionIfNeeded() {
    const session = this.sessionStore.get();
    if (!session || !this.isSessionTokenExpired(session.token)) return;

    if (session.refreshToken && this.isSessionTokenExpired(session.refreshToken)) {
      debug("Expiring session because refresh token has expired", debugSession(session));
      if (this.onSessionExpired) void this.onSessionExpired({ reason: "logged_in_for_too_long" });
      this.clearSession();
    } else if (!session.refreshToken) {
      debug("Expiring session because we don't have a refresh token", debugSession(session));
      if (this.onSessionExpired) void this.onSessionExpired({ reason: "failed_to_refresh_session" });
      this.clearSession();
    } else {
      const { error } = await this.refreshSession(session);
      if (error) {
        if (this.onSessionExpired) void this.onSessionExpired({ reason: "failed_to_refresh_session" });
        this.clearSession();
      }
    }
  }

  public async assertValidSession() {
    this.expireInactiveSession();
    await this.refreshSessionIfNeeded();
    if (!this.sessionStore.get()) {
      const { error } = await this.newSession();
      if (error) throw error;
    }
  }

  public getFetch(next: FetchAPI = fetch) {
    return async (input: URL | RequestInfo, init: RequestInit = {}) => this.handleRequest(input, init, next);
  }

  private async handleRequest(input: URL | RequestInfo, init: RequestInit = {}, fetcher: FetchAPI = fetch) {
    // If we don't have a session, or if it's expired, create a new session first
    await this.assertValidSession();

    // Try the request
    const response = await fetcher(input, this.withAuthorizationHeader(init));

    // Check for invalid/expired sessions not handled by assertValidSession. This could happen e.g.
    // if the session token got corrupt somehow.
    if (response.status === 401) {
      debug("Redia Platform session expired/invalid, got 401 response.", debugSession(this.sessionStore.get()));
      this.clearSession();
      // Burde vi også prøve på nytt her? Litt usikker
    }

    return response;
  }

  private withAuthorizationHeader(init: RequestInit): RequestInit {
    const token = this.sessionStore.get()?.token?.token;
    const headers = new Headers(init.headers ?? {});
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
      // Mark session as active
      this.sessionStore.patch({ lastActiveTime: new Date().toISOString() });
    } else {
      console.error(`Session middleware has no session token.`);
    }
    return {
      ...init,
      headers,
    };
  }
}
