import { PatronCreatedListResponse, PatronListAndItemsBody, PatronLists } from "../client/interfaces";

export const mockFavorites: PatronLists = {
  defaultListId: "1",
  lists: [
    {
      id: "1",
      name: "Favoritter",
    },
    {
      id: "2",
      name: "Huskeliste 1",
    },
    {
      id: "3",
      name: "Huskeliste 2",
    },
  ],
};

export const mockFavorite: PatronListAndItemsBody = {
  list: {
    id: "1",
    items: [
      {
        id: "1",
        publicationId: "1",
        workId: "1",
      },
    ],
    name: "Favoritter",
  },
};

export const mockCreatedFavorite: PatronCreatedListResponse = {
  createdListId: "1",
};
