import { PatronReservation } from "../client/interfaces";
import { mockISOdateString } from "./ISOdateString";

export const mockReservations: PatronReservation[] = [
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:636813,103",
    itemId: "636813",
    publicationId: "636813",
    queueNumber: 1,
    pickup: {
      location: {
        branch: {
          code: "tøb",
        },
      },
      expirationDate: mockISOdateString("date", 1),
    },
    reservationType: "redia.reservation",
    state: "redia.in_queue",
  },
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:724561,178",
    itemId: "724561",
    publicationId: "724561",
    queueNumber: 62,
    pickup: {
      location: {
        branch: {
          code: "hbhv",
        },
      },
      expirationDate: mockISOdateString("date", 4),
      note: "9/ 6",
    },
    reservationType: "redia.reservation",
    state: "redia.ready_for_pickup",
  },
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:469219,176",
    itemId: "469219",
    publicationId: "469219",
    queueNumber: 40,
    pickup: {
      location: {
        branch: {
          code: "tbh",
        },
      },
      expirationDate: mockISOdateString("date", 5),
    },
    reservationType: "redia.reservation",
    state: "redia.in_queue",
  },
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:477106,106",
    itemId: "477106",
    publicationId: "477106",
    queueNumber: 4,
    pickup: {
      location: {
        branch: {
          code: "tbh",
        },
      },
      expirationDate: mockISOdateString("date", 10),
    },
    reservationType: "redia.reservation",
    state: "redia.in_queue",
  },
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:476731,122",
    itemId: "476731",
    publicationId: "476731",
    queueNumber: 19,
    pickup: {
      location: {
        branch: {
          code: "tbh",
        },
      },
      expirationDate: mockISOdateString("date", 10),
    },
    reservationType: "redia.reservation",
    state: "redia.in_queue",
  },
  {
    createdTime: mockISOdateString("datetime", -10),
    id: "ix:476958,110",
    itemId: "476958",
    publicationId: "476958",
    queueNumber: 4,
    pickup: {
      location: {
        branch: {
          code: "tbh",
        },
      },
      expirationDate: mockISOdateString("date", 10),
    },
    reservationType: "redia.reservation",
    state: "redia.in_queue",
  },
];

export const mockReservation: PatronReservation = {
  createdTime: mockISOdateString("datetime", -10),
  id: "ix:636813,103",
  itemId: "636813",
  publicationId: "636813",
  queueNumber: 1,
  pickup: {
    location: {
      branch: {
        code: "tøb",
      },
    },
    expirationDate: mockISOdateString("date", 1),
  },
  reservationType: "redia.reservation",
  state: "redia.in_queue",
};
