import { PublicationHoldings } from "../client/interfaces";

export const mockHoldings: Record<string, PublicationHoldings | undefined> = {
  "650344": {
    locations: [
      {
        items: [
          {
            availableState: "available",
            itemId: "ix:650344,1",
            loanableState: "loanable",
            reserveId: "ix:650344,1",
          },
        ],
        location: {
          branchCode: "TØ",
          branchName: "Tønsberg",
          path: [
            {
              name: "Tønsberg Voksen",
              type: "library",
              code: "TØ",
            },
          ],
        },
        shelfmark: "CDM2 S",
      },
    ],
    numberOfReservations: 0,
    publicationId: "650344",
    reserveId: "650344",
    type: "publication",
  },
};
