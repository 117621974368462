import { Locations } from "../client/interfaces";

export const mockLocations: Locations = {
  branches: [
    {
      code: "mbs",
      name: "Hovedbiblioteket",
      pickup: {
        allow: true,
      },
    },
    {
      code: "hks",
      name: "Filial 1",
      pickup: {
        allow: true,
      },
    },
    {
      code: "mb",
      name: "Filial 2",
      pickup: {
        allow: true,
      },
    },
  ],
  sections: [
    {
      elements: [
        {
          code: "string",
          name: "string",
          parentCode: "string",
          pickup: {
            allow: true,
          },
        },
      ],
      type: "string",
    },
  ],
};
