import { Publication } from "../client/interfaces";

export const mockPublications: Record<string, Publication | undefined> = {
  "636813": {
    additionalIdentifiers: [
      {
        digits: "13",
        type: "isbn",
        value: "9788202132675",
      },
    ],
    binding: {
      type: "literal",
      value: "Innbundet",
    },
    creators: [
      {
        agent: {
          externals: {
            identifiers: [
              {
                type: "norwegian_authority_file",
                value: "90114443",
              },
            ],
          },
          fullName: "Roy Jacobsen",
          id: "18258",
          type: "person",
        },
        roles: [
          {
            roleCode: "aut",
            type: "marc_relator",
          },
        ],
      },
    ],
    identifier: "481",
    images: [
      {
        type: "front_cover",
        url: "https://media.aja.bs.no/f78983a8-1ac7-453e-99d7-51d3d7657c04/cover/original.jpg",
      },
    ],
    languages: [
      {
        code: "nob",
        name: "Bokmål",
      },
    ],
    mediaTypes: [
      {
        code: "redia.book",
        name: "Bok",
      },
    ],
    // publicationDate: {
    //   type: "year",
    //   year: 2013,
    // },
    publishers: [
      {
        places: [
          {
            place: "Oslo",
          },
        ],
        publisher: {
          id: "Cappelen Damm",
          name: "Cappelen Damm",
        },
      },
    ],
    titles: [
      {
        language: {
          code: "nob",
          name: "Bokmål",
        },
        main: "Seierherrene",
        type: "preferred",
      },
    ],
    works: [
      {
        identifier: "1233612",
        relation: "embodies",
      },
    ],
  },
};
