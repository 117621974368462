import {
  PatronReservation,
  RediaPlatform,
  Schemas,
  RediaPlatformProps,
  UserProfileUpdate,
  PatronListInsertItem,
} from "../client/interfaces";
import { mockLoans } from "./mockLoans";
import { mockPatron } from "./mockPatron";
import { mockPublications } from "./mockPublications";
import { mockReservation, mockReservations } from "./mockReservations";
import { SessionStore } from "../client/sessionStore";
import { mockBranches } from "./mockBranches";
import { mockHoldings } from "./mockHoldings";
import { mockLocations } from "./mockLocations";
import { mockLoanHistory } from "./mockLoanHistory";
import { mockFavorite, mockFavorites } from "./mockFavorites";

const mockDataLocalStorageKey = "mock-data";
export const mockLoansLocalStorageKey = mockDataLocalStorageKey + "-loans";
export const mockReservationsLocalStorageKey = mockDataLocalStorageKey + "-reservations";

export const createRediaPlatformMockClient = (props: RediaPlatformProps): RediaPlatform =>
  new RediaPlatformMockClient(props);

/* eslint-disable @typescript-eslint/no-unused-vars */
class RediaPlatformMockClient implements RediaPlatform {
  public environment = "test";
  public customerId = "bstestcmsbib";
  private sessionStore: SessionStore;
  public readonly isMock = true;

  public constructor({ sessionStore }: Pick<RediaPlatformProps, "sessionStore">) {
    this.sessionStore = sessionStore;
  }

  public async checkCredientials(username: string, password: string) {
    // Do nothing
  }

  public async login(username: string, password: string) {
    this.sessionStore.set({
      token: { type: "bearer", token: "21", expiresTime: "2050-01-01T00:00:00.000Z" },
      refreshToken: { type: "bearer", token: "21", expiresTime: "2050-01-01T00:00:00.000Z" },
      user: mockPatron,
      customerId: this.customerId,
      lastActiveTime: new Date().toISOString(),
    });
  }

  public async logout() {
    this.sessionStore.clear();
  }

  public async getConfiguration() {
    const config: Schemas["configuration"]["ConfigurationContent"] = {
      productCode: "content",
      name: "Mockoteket",
    };
    return config;
  }

  public async getCustomerGroups() {
    return [];
  }

  public getUser() {
    return mockPatron;
  }

  public async refreshUserProfile() {
    return mockPatron;
  }

  public async getReservations(): Promise<PatronReservation[]> {
    return getFromLocalStorage(mockReservationsLocalStorageKey) ?? mockReservations;
  }

  public async deleteReservation(reservationId: string) {
    // Do nothing
  }

  public async createReservation(reservationId: string, pickupBranchCode: string): Promise<PatronReservation> {
    return mockReservation;
  }

  public async getLoans() {
    return getFromLocalStorage(mockLoansLocalStorageKey) ?? mockLoans;
  }

  public async renewLoan(loanId: string) {
    const loan = mockLoans[0];
    if (!loan) {
      // Make TS happy
      throw new Error("Loan not found");
    }
    return loan;
  }

  public async getPublications(publicationIds: string[]) {
    return mockPublications;
  }

  public async getHoldings(publicationIds: string[]) {
    return mockHoldings;
  }

  public async getBranches() {
    return mockBranches;
  }

  public async getBranch(branchCode: string) {
    return mockBranches[branchCode];
  }

  public async getLocations() {
    return mockLocations;
  }

  public async getLoanHistory(before_timestamp: string, page?: number, size?: number) {
    return mockLoanHistory;
  }

  public async deleteLoanHistory(loanHistoryId: string) {
    // Do nothing
  }

  public async getList(listId: string) {
    return mockFavorite;
  }

  public async addItemToList(listId: string, item: PatronListInsertItem) {
    // Do nothing
  }

  public async deleteItemFromList(listId: string, itemId: string) {
    // Do nothing
  }

  public async getLists() {
    return mockFavorites;
  }

  public async createList(name: string) {
    return {
      createdListId: "1",
    };
  }

  public async updateListName(listId: string, name: string) {
    // Do nothing
  }

  public async deleteList(listId: string) {
    // Do nothing
  }

  public async updateUserProfile({ phoneNumbers, emails, pickupLocationBranchCode }: UserProfileUpdate) {
    return { patron: mockPatron, type: "patron_updated_and_returned" as const };
  }
}

const getFromLocalStorage = (key: string) => {
  const localStorageValue = localStorage.getItem(key);
  if (!localStorageValue) return undefined;
  try {
    return JSON.parse(localStorageValue);
  } catch (e) {
    console.log("Ugyldig data i local storage");
    return undefined;
  }
};
